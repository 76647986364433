import React, { Component, Fragment } from 'react'
import { connect } from 'kea'
import PropTypes from 'prop-types'
import newsletter from '@otavamedia/om-component-library/lib/kea/newsletter'
import { validateEmail } from '../../../validators'
import { ErrorPlaceholderBox, withErrorBoundary } from '../util/ErrorBoundaries'
import './Newsletter.scss'

@connect({
  props: [
    newsletter, [
      'isSubmitted',
      'hasFailed',
      'isLoading',
      'isNewsletterTaken'
    ],
  ],
  actions: [
    newsletter, [
      'orderNewsletter',
      'setNewsletterTaken'
    ]
  ]
})
class Newsletter extends Component {
  canShowNewsletter = false
  constructor (props) {
    super(props)

    this.state = {
    }
    if (!props.isNewsletterTaken && !props.showAlways) {
      this.canShowNewsletter = true
      props.actions.setNewsletterTaken(this)
    }
  }

  handleSubmit = () => {
    window.open('https://uutiskirjeet.eralehti.fi/', '_blank')
  }

  componentWillUnmount () {
    if (this.canShowNewsletter) {
      this.canShowNewsletter = false
      this.actions.setNewsletterTaken(null)
    }
  }

  render () {
    if (!this.canShowNewsletter && !this.props.showAlways) {
      return null
    }
    return (
      <div styleName="container">
        <div styleName="content">
          <h4 styleName="title">Tilaa Erän uutiskirje</h4>
            <p>{window.om_constants.newsletterText}</p>
            <button onClick={this.handleSubmit}>Tilaa uutiskirje</button>
        </div>
      </div>
    )
  }
}

Newsletter.propTypes = {
  isSubmitted: PropTypes.bool,
  isNewsletterTaken: PropTypes.object,
  actions: PropTypes.object,
  hasFailed: PropTypes.bool,
  isLoading: PropTypes.bool,
  showAlways: PropTypes.bool,
}

export default withErrorBoundary(
  Newsletter,
  ErrorPlaceholderBox()
)
